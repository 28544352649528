/**
 *
 * TermsPage
 *
 *
 */

import React, { memo, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Link from 'components/Elements/Link';

import SEO from 'components/seo';
import theme from 'utils/theme';

const TermsPage = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Privacy Notice" />
      <h1>Ripspot Privacy Notice</h1>
      <p>
        <em>Effective as of 12/28/2020</em>
      </p>
      <ul>
        <li>
          <p>
            <a href="#what_we_do">What We Do</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#collection_of_information">
              Collection and Uses of Your Personal Information
            </a>
          </p>
        </li>
        <li>
          <p>
            <a href="#use_of_our_services">
              Use of our website(s) or online services
            </a>
          </p>
        </li>
        <li>
          <p>
            <a href="#purchase_or_received">
              Purchase or received one of our Services or a business partner
            </a>
          </p>
        </li>
        <li>
          <p>
            <a href="#employment">Apply for employment with Ripspot</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#choices_and_rights">Your Choices and Rights</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#access_correction_deletion">
              Access, Correction or Deletion of Your Information
            </a>
          </p>
        </li>
        <li>
          <p>
            <a href="#sharing_information">Sharing Your Personal Information</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#security">Security</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#children">Children</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#retention">Retention of Personal Information</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#use_of_cookies">
              Use of Cookies and other Web technologies
            </a>
          </p>
        </li>
        <li>
          <p>
            <a href="#forums_and_chat_rooms">Forums and Chat Rooms</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#_How_to_Contact">How to Contact Us</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#updates">Updates to this Privacy Statement</a>
          </p>
        </li>
        <li>
          <p>
            <a href="#other_notices">Other Notices</a>
          </p>
        </li>
      </ul>

      <p>
        Ripspot LLC, a Maine limited liability company ("Ripspot", "we", "us")
        takes its data protection responsibilities seriously. This Privacy
        Notice explains who we are, how we collect, use and share personal
        information about you, and how you can exercise your privacy rights. It
        applies to all individuals who access our website(s), engage in our
        products and/or services ("Services"), or who participate in our
        recruitment activities. We recommend that you read this Privacy Notice
        in full to ensure you are fully informed.
      </p>
      <p>
        You might find external links to third party websites on our website.
        This Privacy Notice does not apply to your use of a third party site.
      </p>
      <h2 id="what_we_do">What We Do</h2>
      <p>
        You can find out more about Ripspot on our homepage or by contacting us
        using the information in the{' '}
        <a href="#_How_to_Contact">How to Contact Us</a> section.
      </p>
      <h2 id="collection_of_information">
        Collection and Uses of Your Personal Information
      </h2>
      <p>We collect and use information about you if you:</p>
      <ul>
        <li>
          <p>Use one of our website(s) or online services</p>
        </li>
        <li>
          <p>Purchase or received one of our services</p>
        </li>
        <li>
          <p>You are identified as a potential customer</p>
        </li>
        <li>
          <p>Work with us as a business partner</p>
        </li>
        <li>
          <p>Apply for employment with Ripspot</p>
        </li>
      </ul>
      <h3 id="use_of_our_services">Use of our Services</h3>
      <p>
        The personal information that we may collect about you when you visit
        our websites broadly falls into the following categories:
      </p>
      <p>
        <strong>Information you provide voluntarily</strong>: We collect
        personal information that you provide voluntarily through our websites,
        for example when completing online forms to contact us. The information
        we collect about you may include some or all of the following:
      </p>
      <ul>
        <li>
          <p>Name</p>
        </li>
        <li>
          <p>
            Contact information, including email address and telephone number(s)
          </p>
        </li>
        <li>
          <p>Information pertinent to fulfilling our Services to you; and</p>
        </li>
        <li>
          <p>
            Any other personal information that you voluntarily choose to
            provide to us.
          </p>
        </li>
      </ul>
      <p>
        If we ask you to provide any other personal information not described
        above, the personal information that you are asked to provide, and the
        reasons why you are asked to provide it, will be made clear to you at
        the point that you are asked to provide it.
      </p>
      <ul>
        <li>
          <strong>Information that we collect automatically</strong>: When you
          visit our websites or use our Services, we may collect or generate
          certain personal information automatically from your device.
          Specifically, this information may include information such as: your
          IP address, device type, unique device identification number,
          browser-type, operating system, broad geographic location (e.g.,
          country or city-level location) and other technical information. We
          may also collect information about how your device has interacted with
          our websites and Service, including the pages accessed and links
          clicked.
        </li>
      </ul>
      <p>
        Collecting this information enables us to better understand the visitors
        who come to our websites and use our Services, where they come from, and
        what content on our websites is of interest to them. We use this
        information for our internal analytics purposes and to improve the
        quality and relevance of our websites and Services to our visitors and
        customers.
      </p>
      <p>
        Some of this information may be collected using cookies and similar
        tracking technology, as explained in our{' '}
        <a href="#_Use_of_Cookies">Cookie Notice</a>.
      </p>
      <p>We will use this information in the following ways:</p>
      <ul>
        <li>
          <strong>Providing our Services</strong>: When you use our websites
          and/or Services, we will use your personal information to provide the
          requested service. For example, to provide you with the content you
          access and request such as downloading content from our websites;
        </li>
        <li>
          <strong>Developing and improving Services</strong>: We use your
          personal information to analyze trends and to track your usage of and
          interactions with our websites and/or Service to the extent it is
          necessary in developing and improving our websites and providing our
          users with more relevant content or functionality;
        </li>
        <li>
          <strong>Handling contact and user support requests</strong>: If you
          fill out a &ldquo;Contact Me&rdquo; web form or request customer
          support, or if you contact us by other means including via a phone
          call, we use your personal information in fulfilling your requests and
          communicating with you;
        </li>
        <li>
          <strong>Promoting the security of our Services</strong>: We process
          your personal information by tracking the use of our websites and/or
          Services, creating aggregated, non-personal data, verifying accounts
          and activity, investigating suspicious activity and enforcing our
          terms and policies, to the extent this is necessary in promoting the
          safety and security of the websites, systems and applications and in
          protecting our rights and the rights of others;
        </li>
        <li>
          <strong>Assessing and improving user experience</strong>: We process
          device and usage data, which in some cases may be associated with your
          personal information, in order to analyze trends that assess and
          improve the overall user experience to the extent it is necessary in
          developing and improving the website and/or Service.
        </li>
        <li>
          <strong>Complying with legal obligations</strong>: We use your
          personal information when cooperating with public and government
          authorities, courts or regulators in accordance with our legal
          obligations under applicable laws, to protect our rights or is
          necessary in protecting against misuse or abuse of our websites and/or
          Services, protecting personal property or safety, pursuing remedies
          available to us and limiting our damages, complying with judicial
          proceedings, court orders or legal processes or to respond to lawful
          requests.
        </li>
      </ul>
      <h3 id="purchase_or_received">Vendors or Business Partners</h3>
      <p>
        We collect and use personal information which is necessary for entering
        into agreements with vendors and business partners. The majority of the
        personal information we collect and use is supplied voluntarily by our
        vendors and business partners. Because of this, if you are a vendor or
        business partner of Ripspot then it will be generally obvious to you
        what personal information we collect and use. This information may
        include:
      </p>
      <ul>
        <li>
          <p>
            Basic information such as your name, the company you work for, your
            position and your relationship to a person;
          </p>
        </li>
        <li>
          <p>
            Contact information such as postal address, email address and
            telephone number(s);
          </p>
        </li>
        <li>
          <p>
            Financial information (such as bank account information, tax
            registration information, billing details) in order to manage and
            process any payments you make to us;
          </p>
        </li>
        <li>
          <p>
            Information held by credit references agencies and fraud prevention
            agencies, in order to assess any application for your use of our
            services;
          </p>
        </li>
        <li>
          <p>
            Identification and background information provided by you or
            collected as part of our business validation process; and
          </p>
        </li>
        <li>
          <p>
            Information and feedback you provide to us about our products and
            services in order to continuously improve the products and services
          </p>
        </li>
      </ul>
      <p>We will use this information in the following ways:</p>
      <ul>
        <li>
          <strong>Providing our Services</strong>: We use your personal
          information to perform our contract with you for the use of our
          Services and to fulfill our obligations under applicable terms of
          use/service;
        </li>
        <li>
          <strong>Promoting the security of Services</strong>: We use your
          personal information by tracking the use of our Services, creating
          aggregated, non-personal data, verifying accounts and activity,
          investigating suspicious activity and enforcing our terms and
          policies, to the extent this is necessary for promoting the safety and
          security of the Services, systems and applications and in protecting
          our rights and the rights of others;
        </li>
        <li>
          <strong>Registering office visitors</strong>: We use your personal
          information for security reasons, to register visitors to our offices
          and to manage non-disclosure agreements that visitors may be required
          to sign, to the extent it is necessary in protecting our offices and
          our confidential information against unauthorized access;
        </li>
        <li>
          <strong>Managing Payments</strong>: If you have provided financial
          information to us, we use your personal information to verify that
          information and to collect payments to the extent that doing so is
          necessary to complete the transaction and perform our contract with
          you;
        </li>
      </ul>
      <h3 id="employment">Apply for employment with Ripspot</h3>
      <p>
        Ripspot collects information from and about candidates in connection
        with available employment opportunities at Ripspot. This information may
        include resumes, identification documents, academic records, work
        history, employment and references. Ripspot uses your personal
        information to match your skills, experience and education with specific
        roles.
      </p>
      <p>
        We collect several types of information from and about you that broadly
        falls into the following categories:
      </p>
      <ul>
        <li>
          <strong>Information you provide voluntarily</strong>: We collect
          personal information that you provide voluntarily when applying for a
          job. The information we collect about you may include some or all of
          the following:
        </li>
      </ul>
      <ul>
        <li>
          Contact information (for example, your name, home and email addresses,
          telephone number)
        </li>
        <li>Nationality and immigration status</li>
        <li>Job title</li>
        <li>
          Compensation information (for example, shares of stock and details
          about any entitlements to shares of stock awarded)
        </li>
        <li>
          Background check and drug screening information (where permitted or
          required by law)
        </li>
        <li>Employment contracts</li>
        <li>Competency assessments and education history</li>
        <li>Information we collect from third parties</li>
      </ul>
      <ul>
        <li>
          <strong>Information collected from other sources</strong>: We may
          obtain information about you from other sources, to the extent
          permitted by applicable law, in connection with your application and
          as part of the interview process. For example, we obtain information
          through your contact with us from third parties such as referees,
          employment research firms, identity verification and background check
          service providers, and other websites on the Internet (subject to such
          third party&rsquo;s privacy policies). For example, you may choose to
          provide us with access to certain personal information stored by third
          parties on professional social media sites (for example, LinkedIn).
        </li>
        <li>
          <strong>
            Information we collect as you navigate through the application site
          </strong>
          : As you navigate through our application site, we may also collect
          certain information from you about your visit to our websites using
          &quot;cookies&quot; and other similar tracking technologies.
        </li>
        <li>
          For further information, please consult our{' '}
          <Link to="/cookies-notice">Cookies Policy</Link>.
        </li>
      </ul>
      <p>
        We may use information that we collect about you for recruitment and
        human resources management purposes at a global level, including:
      </p>
      <ul>
        <li>
          Carrying out and supporting human resources functions and activities,
          including processing and evaluating your application, assessing your
          eligibility to work, making hiring decisions, and managing and
          improving our recruiting and hiring processes
        </li>
        <li>Carrying out obligations under employment and benefits laws</li>
        <li>Managing workplace safety and health</li>
        <li>
          Administering employee participation in benefits, compensation, human
          resources, and training plans and programs
        </li>
        <li>
          Informing you of future employment opportunities (unless you tell us
          you do not want to be contacted for such purposes)
        </li>
        <li>Complying with legal obligations and internal policies</li>
        <li>
          Managing and conducting business activities, including allocating
          company assets and human resources; project management, records
          management, and compilation of audit trails or other reporting tools;
          managing mergers, acquisitions, sales, re‑organizations, or disposals
          and integration with purchaser
        </li>
        <li>
          Managing the online career portal and conducting background and other
          pre‑employment checks to the extent permitted by applicable law
        </li>
      </ul>
      <p>
        If you are hired, your information may be used in connection with your
        employment (for example, to establish an employment record; administer
        employee participation in benefits and training plans and programs; or
        conduct onboarding activities, including granting access to internal
        systems).
      </p>
      <p>
        In general, we do not require job applicants to provide sensitive
        personal information (such as information about your health, race,
        ethnicity, religion, or sexual orientation), but you may provide such
        information voluntarily at your discretion. If you agree to provide this
        information, it will normally be used only for the purposes of diversity
        monitoring and providing a suitable working environment for you (for
        example, if you require disability access) and will not be a factor in
        the hiring or selection process.
      </p>
      <p>
        For certain roles (e.g., United States federal‑facing roles), however,
        we may ask for nationality to confirm that we are in compliance with our
        legal obligations. This information will be viewable in the hiring
        process as it is required for determining eligibility.
      </p>
      <h3 id="choices_and_rights">Your Choices and Rights</h3>
      <p>
        Ripspot respects your rights in how your personal information is used
        and shared. You may request access or corrections to your personal
        Information and make choices about the kinds of marketing materials you
        receive (or choose not to receive marketing from Ripspot at all).
      </p>
      <p>
        We may ask you for additional information to confirm your identity and
        for security purposes, before disclosing the personal information
        request to you. We reserve the right to charge a fee where permitted by
        law.
      </p>
      <p>
        You can exercise your rights by using one of the methods in the{' '}
        <a href="#_How_to_Contact">How to Contact Us</a> section. Subject to
        legal and other permissible considerations, we will make every
        reasonable effort to honor your request promptly or inform you if we
        require further information in order to fulfill your request. We may not
        always be able to fully address your request, for example if it would
        impact the duty of confidentiality we owe to others, or if we are
        legally entitled to deal with the request in a different way.
      </p>
      <h3 id="access_correction_deletion">
        Access, Correction or Deletion of Your Information
      </h3>
      <p>
        If you would like to correct or update your personal information, or to
        request access to or deletion of your personal information, you may
        contact us by using one of the methods listed in the{' '}
        <a href="#_How_to_Contact">How to Contact Us</a> section below including
        your name, email address, account identification, and purpose of the
        request. If you request a change to or deletion of your personal
        information, please note that we may still need to retain certain
        information for recordkeeping purposes, and/or to complete any
        transactions that you began prior to requesting such change or deletion
        (e.g., when you make a purchase or enter a promotion, you may not be
        able to change or delete the personal information provided until after
        the completion of such purchase or promotion). Some of your information
        may also remain within our systems and other records where necessary for
        compliance with applicable law.
      </p>
      <p>
        At your request and where the law requires us to do so, we will confirm
        what personal information we hold about you. You may also have a legal
        right to obtain a copy of your personal information. You can make such a
        request by making a written request in one of the ways described in the{' '}
        <a href="#_How_to_Contact">How to Contact Us</a> section below. We may
        charge a processing fee for this service where permitted by law and we
        will require evidence of your identity before fulfilling your request.
      </p>
      <h2 id="sharing_information">Sharing Your Personal Information</h2>
      <p>
        We may disclose your personal information with third parties for the
        purposes of operating our business, delivering, improving, and
        customizing our Services, sending marketing and other communications
        related to our business, and for other legitimate purposes permitted by
        applicable law or otherwise with your consent.
      </p>
      <p>We may share personal information in the following ways:</p>
      <ul>
        <li>Within Ripspot for purpose of data processing or storage,</li>
        <li>
          With Ripspot business partners or vendors, so that they may share
          information with you about their products or services. To opt-out of
          Ripspot sharing with third parties for their marketing purpose, you
          may contact us by using one of the methods listed in the{' '}
          <a href="#_How_to_Contact">How to Contact Us</a> section below,
        </li>
      </ul>
      <ul type="disc">
        <li>
          With business partners, service vendors, authorized third-party
          agents, or contractors to provide a requested service or transaction.
          Examples include, but are not limited to: processing of orders and
          credit card transactions, and providing customer support,
        </li>
        <li>
          In connection with, or during negotiations of, any merger, sale of
          company assets, consolidation or restructuring, financing, or
          acquisition of all or a portion of our business by or to another
          company.
        </li>
        <li>
          In response to a request for information by a competent authority if
          we believe disclosure is in accordance with, or is otherwise required
          by, any applicable law, regulation or legal process.
        </li>
        <li>
          With law enforcement officials, government authorities, or other third
          parties as necessary to comply with legal process or meet national
          security requirements; protect the rights, property, or safety of
          Ripspot, its business partners, you, or others; or as otherwise
          required by applicable law.
        </li>
        <li>
          In aggregated, anonymized, and/or de-identified form which cannot
          reasonably be used to identify you.
        </li>
        <li>If we otherwise notify you and you consent to the sharing.</li>
      </ul>
      <h2 id="security">Security</h2>
      <p>
        We intend to protect the personal information entrusted to us and treat
        it securely in accordance with this Privacy Notice. Ripspot implements
        physical, administrative, and technical safeguards designed to protect
        your information from unauthorized access, use, or disclosure. We also
        contractually require that our suppliers protect such information from
        unauthorized access, use, and disclosure. The Internet, however, cannot
        be guaranteed to be 100% secure, and we cannot ensure or warrant the
        security of any personal information you provide to us.
      </p>
      <h2 id="children">Children</h2>
      <p>
        We do not knowingly collect personal information online from anyone
        under the age of 18. If we learn that we have collected personal
        information from anyone under the age of 18, we will take appropriate
        measures to investigate and address the issue promptly.
      </p>
      <h2 id="retention">Retention of Personal Information</h2>
      <p>
        We will store your personal information for as long as is reasonably
        necessary for the purposes for which it was collected, as explained in
        this Privacy Notice. In some circumstances we may store your personal
        information for longer periods of time, for instance where we are
        required to do so in accordance with legal, regulatory, tax, or
        accounting requirements. In specific circumstances we may store your
        personal information for longer periods of time so that we have an
        accurate record of your dealings with us in the event of any complaints
        or challenges, or if we reasonably believe there is a prospect of
        litigation relating to your personal information or dealings. We may
        also retain your job application data to consider you for other job
        opportunities where such retention is permitted by law, unless you do
        not permit us to retain your data for such purposes.
      </p>
      <h2 id="use_of_cookies">Use of Cookies and other Web technologies</h2>
      <p>
        We collect information about how you interact with our website(s) and
        some Ripspot Services through the use of cookies, pixel tags, and
        similar technologies. Please refer to our{' '}
        <strong>Cookies Notice</strong> for more information on our use of
        cookies and similar technologies, how you can manage cookies and how we
        respond to Do Not Track signals.
      </p>
      <h2 id="forums_and_chat_rooms">Forums and Chat Rooms</h2>
      <p>
        If you participate in a discussion forum, local communities, or chat
        room on a Ripspot website, you should be aware that the information you
        provide there (i.e. your public profile) will be made broadly available
        to others, and could be used to contact you, send you unsolicited
        messages, or for purposes neither Ripspot nor you have control over.
        Also, please recognize that individual forums and chat rooms may have
        additional rules and conditions. Ripspot is not responsible for the
        personal information or any other information you choose to submit in
        these forums. To request removal of your personal information from our
        blog or community forum, contact us by using one of the methods listed
        in the <a href="#_How_to_Contact">How to Contact Us</a> section below.
        In some cases, we may not be able to remove your personal information,
        in which case we will let you know if we are unable to do so and why.
      </p>
      <h2 id="_How_to_Contact">How to Contact Us</h2>
      <p>
        If you have any questions, concerns or complaints regarding our
        compliance with this notice and the data protection laws, or if you wish
        to exercise your rights, we encourage you to first contact us using the
        contact details below. We will investigate and attempt to resolve
        complaints and disputes and will make every reasonable effort to honor
        your wish to exercise your rights as quickly as possible and, in any
        event, within the timescales provided by data protection laws.
      </p>
      <p>
        Email: support@ripspot.com
        <br />
        Mail: Ripspot LLC.
        <br />
        c/o Privacy
        <br />
        PO Box 4262
        <br />
        Portland, ME 04101
        <br />
        <br />
        <strong id="updates">Updates to this Privacy Statement:</strong>
        <br />
        <br />
        We may amend this notice from time to time to keep it up to date with
        legal requirements and the way we operate our business. Please regularly
        check for the latest version of this notice. If we make significant
        changes to this Privacy Notice, we will seek to inform you by notice on
        our website or by email.
      </p>
      <h3 id="other_notices">Other Notices</h3>
      <p>
        <a href="/cookies-notice">Cookies Notice</a>
      </p>
    </Container>
  </ThemeProvider>
);
TermsPage.propTypes = {};

// const mapStateToProps =  props => ({});
const withConnect = connect();
export default compose(memo, withConnect)(TermsPage);

const Container = styled.div`
  color: ${theme.colors.Primary};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => theme.styles.middle}
  max-width: 680px;
  margin: 0 auto;
  padding: 2em 1em;
  padding-bottom: 10em;
  h1 {
    color: black;
    font-size: 4.2em;
    margin-top: 2em;
  }
  h2 {
    color: black;
    margin-top: 2em;
    align-self: flex-start;
  }
  h3 {
    font-size: 2em;
    margin-top: 1em;
    color: black;
    align-self: flex-start;
  }
  p {
    font-size: 1.7em;
    color: black;
    margin: 1em 0;
    align-self: flex-start;
    &:first-of-type {
      margin-top: 2.5em;
    }
  }
  a {
    text-decoration: underline;
  }
  ul {
    align-self: flex-start;
    padding-left: 3em;
    list-style: disc;
    li {
      font-size: 1.7em;
      color: black;
      margin: 0.5em 0;
      font-family: 'Montserrat', sans-serif;
      line-height: 1.5em;
      p {
        font-size: inherit;
        margin: 0 !important;
      }
    }
  }
`;
